import React from 'react'
import './education.css'

export const education = () => {
  return (
    <section id='education'>
        <div className='eduBach'>


        </div>
    </section>
  )
}
export default education
